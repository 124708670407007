'use strict';
const video = {};
const YTPlayer = require('yt-player');
const players = '.youtube-player';
const cover = '.cover-image';
const iconPlay = '.icon-play';
const ctaPlay = '.cta-play';
const carousel = '.experience-dianaComponents-heroBanner .carousel';
const arrow = '.arrow.bounce';
const indicators = '.experience-dianaComponents-heroBanner .carousel-indicators';

const options = {
    autoplay: 0,
    captions: 0,
    controls: 0,
    related: 0,
    width: 1920,
    height: 1080,
    modestBranding: 1
};

video.init = function() {
    if($(players).length > 0) {
        $(players).each(function() {
            if($(this).data('src')) {
                const player = new YTPlayer(this, options);
                const src = $(this).data('src');
                player.load(src);
                player.setVolume(0);

                if($(this).data('autoplay') && $(this).data('autoplay') === true) {
                    player.play();
                }

                player.on('playing', function() {
                    $(cover).fadeOut();
                    $(indicators).fadeOut();
                    $(arrow).fadeOut();
                    $(carousel).carousel('pause');
                });

                player.on('paused', function() {
                    $(cover).fadeIn();
                    $(indicators).fadeIn();
                    $(arrow).fadeIn();
                    $(carousel).carousel('cycle');
                });

                player.on('ended', function() {
                    player.play();
                });

                $(iconPlay).click(function() {
                    player.play();
                });

                $(ctaPlay).click(function() {
                    player.play();
                });

                $(carousel).on('slide.bs.carousel', function() {
                    player.pause();
                });
            }
        });
    }
};


module.exports = video;

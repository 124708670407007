'use strict';

const text = {};


text.init = function() {
    var readMore = $('[data-more]');
    var showMore = '.show-more';

    if(readMore.length > 0) {
        var showMoreLink = $('<div>' + readMore.data('more') + '</div>');
        showMoreLink.addClass('show-more-link link');
        showMoreLink.css({
            position: 'absolute',
            top: '70%'
        });
        $(showMore).after(showMoreLink);

        showMoreLink.on('click.more', function() {
            $(showMore).addClass('show');
            $(showMore).css('max-height', $(showMore + ' *').height());
            showMoreLink.css({
                top: '90%'
            });
            showMoreLink.html(readMore.data('less')).on('click.less', function() {
                $(showMore).removeClass('show');
                $(showMore).css('max-height', 80);
                showMoreLink.css({
                    top: '70%'
                });
                showMoreLink.html(readMore.data('more'));
                $(this).off('click.less');
            });
        });
    }
};

module.exports = text;

'use strict';
const hero = {};
const arrow = '.arrow';
const clickableImage = '.clickable-banner';
const heroImageCta = '.heroImage-cta';

hero.init = function() {
    if($(arrow).length > 0) {
        $(window).scroll(function() {
            $(arrow).css('opacity', 1 - $(window).scrollTop() / 80);
        });
    }

    $(heroImageCta).click(function(e) {
        e.preventDefault();
        e.stopPropagation();

        var ctaUrl = $(this).find('a').attr('href');

        // If in Page Designer the CTA field contains a link with the checkbox "Open in New Window" checked,
        // the internal anchor element has the attribute "target" equal to "_blank"
        var openCtaUrlInNewTab = $(this).find('a').attr('target') === '_blank' ? true : false;

        if (ctaUrl && openCtaUrlInNewTab === true) {
            window.open(ctaUrl);   // Open url in a new tab
        } else if (ctaUrl && openCtaUrlInNewTab === false) {
            window.location = ctaUrl;  // Open url in the same tab
        }
    });

    $(clickableImage).click(function() {
        var bannerUrl = $(this).attr('data-url');
        var openBannerUrlInNewTab = $(this).attr('data-openBannerUrlInNewTab');

        if (openBannerUrlInNewTab) {
            window.open(bannerUrl);   // Open url in a new tab
        } else {
            window.location = bannerUrl;  // Open url in the same tab
        }
    });
};


module.exports = hero;
